
import React, { useState } from "react";//, useEffect
import { Redirect } from "react-router-dom";
import { postLogin } from '../../services/user-service';
import {
  setAccessToken as saveSessionInCookie,
  isLoggedIn,

} from "../../services/user-service";
import {
  ValidationErrorSC,
} from "../../components/common/common-style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

export default function Signin(props) {
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();
    var { uname, pass } = document.forms[0];

    postLogin(uname.value, pass.value)
      .then((response) => {
        let result = response?.data;

        if (result?.success === 1) {

          const storeData = async (result) => {
            try {
              localStorage.setItem('email', result.data.email);
              localStorage.setItem('userType', result.data.userType);
              //         console.log(result);
              saveSessionInCookie();
              // return <Redirect to={"/"} />;
              setIsSubmitted(true);

              //alert('hello');console.log('done');
            } catch (e) {
              console.log(e);
              // saving error
            }
          };
          storeData(result);
        } else {
          setTimeout(() => {
            console.log(result);
            console.log(response);
            // setLoading(false);
            let errorMessage;
            if (!response) { errorMessage = 'System is upgrading, Please try after some time.'; }
            else { errorMessage = result?.message; }
            setErrorMessages({ name: "error", message: errorMessage }); //alert(errorMessage);
          }, 1000);
        }
      })
      .catch((error) => {
        //Hide Loader
        // setLoading(false);
        console.error(error);
      });
  };
  // error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <ValidationErrorSC>{errorMessages.message}</ValidationErrorSC>
    );
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        {isLoggedIn() && isSubmitted ?
          <Redirect to={Routes.DashboardOverview.path} />
          :
          <Container>
            <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Sign in to our platform</h3>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label> Employee Id</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control autoFocus required name="uname" type="text" placeholder="Enter your employee Id" />

                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label> Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control required name="pass" type="password" placeholder="Enter your password" />
                        </InputGroup>
                        {renderErrorMessage("error")}
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input id="defaultCheck5" className="me-2" />
                          <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                        </Form.Check>
                        {/* <Card.Link as={Link} to={Routes.Signup.path} className="small text-end">Lost password?</Card.Link> */}
                      </div>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                      Sign in
                    </Button>
                  </Form>

                  <div className="mt-3 mb-4 text-center">
                    <span className="fw-normal">Welcome to the Pace Delivery</span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      PaceDelivery@2016-2022
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        }
      </section>

    </main>
  );
};
