// USER TYPES
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_LOADING = "FETCH_USERS_LOADING";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

//DELIVERIES
export const FETCH_DELIVERIES = "FETCH_DELIVERIES";
export const UPDATE_DELIVERIES = "UPDATE_DELIVERIES";
export const FETCH_DELIVERIIES_LOADING = "FETCH_DELIVERIIES_LOADING";
export const FETCH_DELIVERIES_ERROR = "FETCH_DELIVERIES_ERROR";
export const UPDATE_DELIVERY_STATUS = 'UPDATE_DELIVERY_STATUS';

//DASHBOARDS
export const FETCH_DASHBOARDS_LOADING = "FETCH_DASHBOARDS_LOADING";
export const FETCH_DASHBOARDS_ERROR = "FETCH_DASHBOARDS_ERROR";
export const FETCH_DASHBOARDS = "FETCH_DASHBOARDS";
