import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, } from '@fortawesome/free-solid-svg-icons'; //faCheck, faCog, faSearch
import { Breadcrumb, } from '@themesberg/react-bootstrap'; //Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown
import {
  //setAccessToken as saveSessionInCookie,
  isLoggedIn,
} from "../services/user-service";
import { Redirect } from "react-router-dom";
import { Routes } from "../routes";
//import { DeliveryTransactionsTable } from "../components/Tables";
//import { PageTrafficTable, RankingTable } from "../components/Tables";

import { Datatable } from "@o2xp/react-datatable";
import { chunk } from "lodash";
import { deliveryOptions } from "../constants/TableOptions";
import { getAllDeliveries, updateDeliveryStatus } from "../actions/delivery-actions";
import DatePicker from 'react-datepicker';
import "../assets/scss/react-datepicker.css";
import moment from "moment-timezone";

export default function DeliveryTransactions() {

  //let localStorePackageVersion = localStorage.getItem('build-version');
  //const [loading, setLoading] = useState(false);
  //const [totalCount, setTotalCount] = useState(0);
  const [userType] = useState(localStorage.getItem('userType')); //localStorage.getItem('build-version');
  const [userEmail] = useState(localStorage.getItem('email')); //localStorage.getItem('build-version');//
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const getData = useSelector((store) => store.deliveryStates.deliveries);
  const getRowsData = useSelector((store) => store.deliveryStates.deliveries.data.rows);
  const deliveries = useSelector((store) => {
    return store?.deliveryStates?.deliveries;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateDeliveryStatus());
  }, [getRowsData]);


  useEffect(() => {
    if (dateRange[0] && dateRange[1]) dispatch(getAllDeliveries(userEmail, (moment(dateRange[0]).format("YYYY-MM-DD") + "," + moment(dateRange[1]).format("YYYY-MM-DD"))));
    if (dateRange[0] === null && dateRange[1] === null && userEmail !== null) dispatch(getAllDeliveries(userEmail, null));
  }, [dateRange, dispatch, userEmail]);


  // const actionsRow = ({ type, payload }) => {
  //   console.log(type);
  //   console.log(payload);
  // };

  const refreshRows = (deliveries) => {
    const { rows } = getData?.data;
    const randomRows = Math.floor(Math.random() * rows.length) + 1;
    const randomTime = Math.floor(Math.random() * 4000) + 1000;
    const randomResolve = Math.floor(Math.random() * 10) + 1;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (randomResolve > 3) {
          resolve(chunk(rows, randomRows)[0]);
        }
        reject(new Error("err"));
      }, randomTime);
    });
  };

  deliveries.data.rows.forEach(row => {
    if (row.LOCATION !== null && typeof row.LOCATION == "string" && row.LOCATION.includes("- ")) {
      row.LOCATION = <a href={'http://www.google.com/maps/place/' + row?.LOCATION.toString().replace('-', ',')} target="_new">View</a>;
    }

    if (row.PHOTO !== null && typeof row.PHOTO == "string" && row.PHOTO.includes("http")) {
      row.PHOTO = <a href={row.PHOTO} target="_new"><img
        alt="paceDelivery.in"
        src={row.PHOTO}
        width="50"
        height="50"
      /></a>;
    }
    if (row.SIGNATURE !== null && typeof row.SIGNATURE == "string" && row.SIGNATURE.includes("http")) {
      row.SIGNATURE = <a href={row.SIGNATURE} target="_new"><img
        alt="paceDelivery.in"
        src={row.SIGNATURE}
        width="50"
        height="50"
      /></a>;
    }
  });

  if (!isLoggedIn()) return <Redirect to={Routes.Signin.path} />;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Deliveries</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Deliveries</h4>
          <p className="mb-0">Today deliveries as per the latest results.</p>

          <DatePicker
            dateFormat="MMMM d, yyyy"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              console.log('ok');
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText="Click to select a date range"
          //selected="August 31, 2022 - August 31, 2022"
          />
          <p className="mb-0">Total Results:-{deliveries ? deliveries.data.rows.length : deliveryOptions.data.rows.length}</p>

        </div>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div> */}
      </div>

      {/* <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">

        </Row>
      </div> */}

      {/* {console.log('deliveries', deliveries)} */}
      <Datatable
        options={deliveries ? deliveries : deliveryOptions}
        stripped
        refreshRows={refreshRows}
        // actions={actionsRow}
        forceRerender
      />

    </>
  );
};