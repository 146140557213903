//http-services

import { HTTPClient } from "./api-service";
import Config from '../constants/Config';
//import { assertEnumDeclaration } from "@babel/types";
import Cookies from "js-cookie";

/*
export function getUserManagement() {
  return HTTPClient({
    url: Config.userManagement,
  });
}
*/

export async function getWebUsers(email = null, userType = null) {
  const formBody = {
    email: email,
    userType: userType,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.getWebUsers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
  // return HTTPClient({
  //   url: Config.userManagement,
  // });
}

/*
export async function getAllConsumers(email = 'Test@mail.com', userType = 'executive') {
  const formBody = {
    email: email,
    userType: userType,
  };
  return await HTTPClient({
    url: Config.getAllConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
}*/

/*
export async function getAllWebConsumers(email) {
  const formBody = {
    email: email,
   // userType: userType,
  };
  return await HTTPClient({
    url: Config.getAllWebConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
}*/

export async function updateWebUser(data) {
  return await HTTPClient({
    url: Config.updateWebUser,
    method: "POST",
    data: data,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
}

export async function postLogin(email, password, captchaValue = null) {
  const formBody = {
    email: email,
    password: password,
  };

  if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.login,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}

/*
export async function photoSubmission(employeeId, dateTime, barCode, photo = 'No Photo') {

  if (photo !== null) {
    let formData = new FormData();

    formData.append('data', {
      // uri: photo,//uri,
      //name: `photo.${fileType}`,
      filename: 'avatar-jpeg.jpeg',
      data: photo,
      type: `image/jpeg`,
    });
    //photo.base64 = 'ok'

    //console.log(formData);
    return await HTTPClient({
      url: Config.photoSubmission,
      method: "POST",
      data: photo,//{ name: 'avatar-png', filename: 'avatar-png.png', type: 'image/png', data: photo },
      skipAuth: true,
      headers: { "content-type": "multipart/form-data" }
    }).then((response) => {
      console.log(response);
      alert(response);
      return response;
    });
    
  }

}*/
/*
export async function photoSubmission2(employeeId, dateTime, barCode, photo = 'No Photo') {

  function uploadImageAsync(photo) {
    let apiUrl = 'http://pacedelivery.in/api/upload';

    // Note:
    // Uncomment this if you want to experiment with local server
    //
    // if (Constants.isDevice) {
    //   apiUrl = `https://your-ngrok-subdomain.ngrok.io/upload`;
    // } else {
    //   apiUrl = `http://localhost:3000/upload`
    // }
    //let uriParts = photo.uri.split('.');
    let uriParts = photo.split('.');
    let fileType = uriParts[uriParts.length - 1];

    let formData = new FormData();
    formData.append('photo', {
      uri: photo,//uri,
      name: `photo.${fileType}`,
      type: `image/${fileType}`,
    });
    
      // let formData = new FormData();
      // formData.append('photo', { uri: photo, name: 'ABC.JPG', type: 'image/png', filename: 'imageName.png' });
      // formData.append('Content-Type', 'image/png');
     
    const formBody = {
      employeeId: employeeId,
      dateTime: dateTime,
      barCode: barCode,
      //photo: photo,
    };
    // formData.append('employeeId', employeeId);
    // formData.append('dateTime', dateTime);
    // formData.append('barCode', barCode);
    //formData.append('Content-Type', 'image/png');
    console.log('reached', formData);
    let options = {
      method: 'POST',
      body: formData,
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'multipart/form-data; application/json; charset=UTF-8',//
        //"Content-Type": "application/json; charset=UTF-8",
      },
    };

    return fetch(Config.photoSubmission, options);
  }

  async function handleImagePicked(photo) {
    let uploadResponse, uploadResult;

    try {
      // this.setState({
      //   uploading: true
      // });

      // if (!pickerResult.cancelled) {
      uploadResponse = await uploadImageAsync(photo);
      uploadResult = await uploadResponse.json();
      console.log(uploadResponse);
      console.log({ uploadResponse });
      // this.setState({
      //   image: uploadResult.location
      // });
      // }
    } catch (e) {
      console.log({ uploadResponse });
      console.log({ uploadResult });
      console.log({ e });
      alert('Upload failed, sorry :(');
    } finally {
      console.log('Upload done,');
      // this.setState({
      //   uploading: false
      // });
    }

    // return await HTTPClient({
    //   url: Config.photoSubmission,
    //   method: "POST",
    //   data: formData,
    //   skipAuth: false,
    //   headers: { "content-type": "multipart/form-data", "otherHeader": "foo" }
    // }).then((response) => {
    //   console.log(response);
    //   alert(response);
    //   return response;
    // });
  }
  handleImagePicked(photo);

}
*/
/*
export async function deliveryFormSubmission(employeeId, dateTime, location = 'No Location', address, barCode, name, phone, status, relation, photo = 'No Photo', comment) {
  const formBody = {
    employeeId: employeeId,
    dateTime: dateTime,
    location: location,
    address: address,
    barCode: barCode,
    name: name,
    phone: phone,
    status: status,
    relation: relation,
    photo: photo,
    comment: comment,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.deliverySubmission,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}
*/
/*
export async function userRegistrations(name, email, password, phone = null, age = null, address = null, userType = 'executive') {
  const formBody = {
    name: name,
    email: email,
    password: password,
    age: age,
    phone: phone,
    address: address,
    userType: userType,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.userRegistrations,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}
*/
/*
export async function getDatabyBarcde(employeeId, barCode) {
  const formBody = {
    employeeId: employeeId,
    barCode: barCode,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.getConsumerDetails,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    // const token = response.data.token;
    // const user = jwt(token); // decode your token here
    // console.log(user);
    // console.log(response.data);
    // localStorage.setItem('token', token);
    //dispatch(actions.authSuccess(token, user));
    return response.data;
  }).catch(err => {
    console.log('login Failed', err);
    //dispatch(actions.loginUserFail());
  });
}
*/
/*
// some logic
axios.post(`${axios.defaults.baseURL}/auth`, { email, password })
  .then(res => {
    const token = res.data.token;
    const user = jwt(token); // decode your token here
    localStorage.setItem('token', token);
    dispatch(actions.authSuccess(token, user));
  })
  .catch(err => {
    dispatch(actions.loginUserFail());
  });
*/

export const setAccessToken = () => {
  return Cookies.set(Config.accessTokenKey, true, 3600);
};

export const getAccessToken = () => {
  return Cookies.get(Config.accessTokenKey);
};

export const isLoggedIn = () => {
  return getAccessToken() ? true : false;
};

export const logout = () => {
  // Clear all local storage related login
  localStorage.clear();
  Cookies.remove(Config.accessTokenKey);
  window.location.reload();
};

export const logOff = () => {
  // Clear all local storage related login
  localStorage.clear();
  Cookies.remove(Config.accessTokenKey);
};


// Todo: Set Refresh Token from cookie
// export const setRefreshToken = (token) => {
//   return true;
// };
