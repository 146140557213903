//http-services

import { HTTPClient } from "./api-service";
import Config from '../constants/Config';
//import Cookies from "js-cookie";
import { deliveryOptions } from "../constants/TableOptions";

/*
export async function getAllConsumers(email = 'Test@mail.com', userType = 'executive') {
  const formBody = {
    email: email,
    userType: userType,
  };
  return await HTTPClient({
    url: Config.getAllConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
}*/

export async function getAllWebConsumers(email = null, dateRange = null) {
  const formBody = {
    email: email,
    // userType: userType,
    dateRange: dateRange
  };
  //setLoading(true);
  let getTableData = [];
  let updatedOptionsData = deliveryOptions;
  //console.log(4);
  return await HTTPClient({
    url: Config.getAllWebConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    // console.log(6);
    //Hide Loader
    let result = response.data;
    // setLoading(false);
    // If server response message same as Data Matched
    if (result?.success === 1) {
      // console.log(7);
      // if (result.data.length) setTotalCount(result.data.length);
      let i = 1;
      result.data.forEach((element, index) => {
        let columnValue;
        Object.entries(element).forEach((val, key) => {
          if (val[0] === 'ID') {
            columnValue = i; i++; //
            element['ID'] = columnValue;
          } else if (val[0] === 'LOCATION') {
            element['LOCATION'] = val[1].replace(/,/g, "-");
            //val.replace(/28.5959254/g, "-")
          } else {
            columnValue = val[1] === '' ? '-' : val[1];
            element[val[0]] = columnValue;
          }
        });
        //   //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.7856949611337!2d77.37031491469001!3d28.666134782404498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xccb563ffcb2ebaf1!2zMjjCsDM5JzU5LjAiTiA3N8KwMjInMjAuOSJF!5e0!3m2!1sen!2sin!4v1657949387675!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        //   //https://www.google.com/maps/place/28%C2%B039'58.1%22N+77%C2%B022'21.0%22E/@28.6661348,77.3703149
        //console.log(8);
        getTableData.push(element);
      });
      //  setTableData(getTableData);
      //  setCsvData(result.data);
      //updatedOptionsData = deliveryOptions;
      // console.log(9);
      updatedOptionsData.data.rows = getTableData;
      //  setOptionsData(updatedOptionsData);
    }
    // console.log(10);
    return updatedOptionsData;
  });
}

export async function getAllDashboards(email = null) {
  const formBody = {
    email: email,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.getAllDashboards,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}

/*
export async function deliveryFormSubmission(employeeId, dateTime, location = 'No Location', address, barCode, name, phone, status, relation, photo = 'No Photo', comment) {
  const formBody = {
    employeeId: employeeId,
    dateTime: dateTime,
    location: location,
    address: address,
    barCode: barCode,
    name: name,
    phone: phone,
    status: status,
    relation: relation,
    photo: photo,
    comment: comment,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.deliverySubmission,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}
*/
