import {
  FETCH_DELIVERIES,
  FETCH_DELIVERIIES_LOADING,
  FETCH_DELIVERIES_ERROR,
  UPDATE_DELIVERY_STATUS,
  FETCH_DASHBOARDS_LOADING,
  FETCH_DASHBOARDS_ERROR,
  FETCH_DASHBOARDS,
} from "../constants/types";

import * as DeliveryService from "../services/delivery-service";

export const getAllDeliveries = (userEmail, dateRange) => (dispatch) => {
  dispatch({
    type: FETCH_DELIVERIIES_LOADING,
    payload: true,
  });
  DeliveryService.getAllWebConsumers(userEmail, dateRange).then((response) => {
    dispatch({
      type: FETCH_DELIVERIES_ERROR,
      payload: response.isError,
    });
    //console.log(1);
    if (!response.isError) {
      //console.log(2);
      /* Need to validate Response model if there is any missing params 
       in object response then front-end response model automatically handle 
       that response payload and will prevent the unwanted error */
      //response = toUserModel(response);
      dispatch({
        type: FETCH_DELIVERIES,
        payload: response,
      });
      //console.log(3);
    }
  });
};

export const getAllDashboards = (email) => async (dispatch) => {
  dispatch({
    type: FETCH_DASHBOARDS_LOADING,
    payload: true,
  });
  await DeliveryService.getAllDashboards(email).then((response) => {
    dispatch({
      type: FETCH_DASHBOARDS_ERROR,
      payload: response.success === 0 ? response.data.message : '',
    });

    if (!response.isError && response.data.success === 1) {
      /* Need to validate Response model if there is any missing params 
       in object response then front-end response model automatically handle 
       that response payload and will prevent the unwanted error */
      //response = toUserModel(response);
      dispatch({
        type: FETCH_DASHBOARDS,
        payload: response?.data?.data,
      });
      //console.log(3);
    } else if (response?.data?.success === 0) {
      //alert('Server is in maintenance node. Please after some time.');
      console.log(response?.data?.message);
    }
  });
};

export const loadDeliveries = (response) => (dispatch) => {
  dispatch({
    type: FETCH_DELIVERIES,
    payload: response,
  });
};

export const updateDeliveryStatus = (response) => (dispatch) => {
  dispatch({
    type: UPDATE_DELIVERY_STATUS,
    payload: false,
  });
};