import styled from "styled-components";

export const InputTextSC = styled.input`
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const InputPasswordSC = styled.input`
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ValidationErrorSC = styled.div`
  margin-top: 20px;
  color: red;
  font-size: 15px;
  margin-left: 5px;
`;
