/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
import {
  FETCH_USERS,
  FETCH_USERS_LOADING,
  FETCH_USERS_ERROR,
} from "../constants/types";

const initialState = {
  users: [],
  usersLoading: true,
  usersError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
        usersLoading: false,
        usersError: false,
      };
    case FETCH_USERS_LOADING:
      return {
        ...state,
        usersLoading: action.payload,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        usersError: action.payload,
      };

    default:
      return state;
  }
}
