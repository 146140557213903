
import { faCalendarCheck, faComment } from '@fortawesome/free-solid-svg-icons';

import Profile1 from "../assets/img/team/profile-picture-1.jpg";
import Profile2 from "../assets/img/team/profile-picture-2.jpg";
import Profile6 from "../assets/img/team/profile-picture-6.jpg";
import Profile4 from "../assets/img/team/profile-picture-4.jpg";


export default [
  {
    "id": 1,
    "image": Profile1,
    "name": "Roop verma",
    "statusKey": "200",
    "statusKey2": "150",
    "icon": faCalendarCheck,
    "btnText": "Invite"
  },
  {
    "id": 2,
    "image": Profile2,
    "name": "Shiv singh",
    "statusKey": "200",
    "statusKey2": "150",
    "icon": faCalendarCheck,
    "btnText": "Message"
  },
  {
    "id": 3,
    "image": Profile6,
    "name": "Raju jha",
    "statusKey": "200",
    "statusKey2": "150",
    "icon": faCalendarCheck,
    "btnText": "Invite"
  },
  {
    "id": 4,
    "image": Profile4,
    "name": "Satendra",
    "statusKey": "200",
    "statusKey2": "150",
    "icon": faComment,
    "btnText": "Message"
  }
];